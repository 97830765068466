import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: 'DE4D2D',
        secondary: '000000',
        accent: '00ff00'
      }
    }
  }
})
