<template>
  <v-row class="my-5">
    <v-col>
      <h1
        class="d-flex flex-column flex-md-row justify-center text-center text-uppercase font-weight-regular"
      >
        <v-icon x-large color="primary" class="mr-md-5">
          {{ icon }}
        </v-icon>
        {{ title }}
      </h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>
